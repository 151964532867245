import React, {useContext, useState} from "react";

export const AppContext = React.createContext({});

export const AppProvider = (props) => {
	const {
		children
	} = props;

	const [message, setMessage] = useState();

	const contextValue = {
		message,
		setMessage
	}

	return (
		<AppContext.Provider value={contextValue}>
			{children}
		</AppContext.Provider>
	)
}

export const useAppContext = () => useContext(AppContext);