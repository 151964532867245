// Ropsten
// export const CONTRACT = "0x52e63622a9988F399c49e86c4F8550120417c5AA";
// export const STAKING_CONTRACT = "0xA5f05ef7ca7CE5b737336A788736071bCf567e4d";
// export const ARCADA_TOKEN_CONTRACT = "0x505d22332560CA1C433DC1d690119c55C18AbB14";
// export const GAME_CONTRACT = "0xBE8135c441AB3E67A65B44691AE9e8027c9F16a2";
// export const ETHERSCAN_TRANSACTION_ENDPOINT = "https://ropsten.etherscan.io/tx";
// export const ETHERSCAN_ADDRESS_ENDPOINT = "https://ropsten.etherscan.io/address";
// export const MTG_GAMING_BASE_URL = "https://mtg-gaming.herokuapp.com"

// Main 

export const CONTRACT = "0x49907029e80dE1cBB3A46fD44247BF8BA8B5f12F";
export const STAKING_CONTRACT = "0x2Eb255a465C828837d6e8Ba73072eC2c965DcF13";
export const ARCADA_TOKEN_CONTRACT = "0x22d811658Dc32293fbB5680EC5df85Cc2B605dC7";
export const GAME_CONTRACT = "0xDFb22Cd0447aa9cCf09c5386FF19D5AD035d32bc";
export const ETHERSCAN_TRANSACTION_ENDPOINT = "https://etherscan.io/tx";
export const ETHERSCAN_ADDRESS_ENDPOINT = "https://etherscan.io/address";
export const MTG_GAMING_BASE_URL = "https://mtg-gaming.herokuapp.com"