import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Wrapper } from "./Components/Wrapper";
import { Staking } from "./Routes/Staking";
import { GameOver } from "./Routes/GameOver";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Wrapper component={<Staking />} />} />
          <Route path="/game" element={<Wrapper component={<GameOver />} />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
