import { useCallback } from "react";
import * as TokenUtils from "../utilities/tokenUtils";

export const AddToMetaMask = (props) => {
	/**
	 * 	return await addTokenToMetaMask({
	 * 		ethereum: window.ethereum,
	 * 		tokenAddress: ARCADA_TOKEN_CONTRACT,
	 * 		tokenSymbol: 'ARCADA',
	 * 		tokenDecimals: 18,
	 * 		image: 'https://www.morethangamersnft.io/static/image/logo.svg',
	 * 	})
	 */

	const {
		tokenAddress,
		tokenSymbol,
		tokenDecimals,
		image,
	} = props;

	const addTokenToWallet = useCallback(() => {
		TokenUtils.addTokenToMetaMask({
			ethereum: window.ethereum,
			tokenAddress,
			tokenSymbol,
			tokenDecimals,
			image,
		}).catch((error) => {
			console.error(error);
		})
	}, [tokenAddress, tokenSymbol, tokenDecimals, image]);

	if (!window.ethereum) {
		return null;
	}

	return (
		<div className={'add-to-metamask-button'} onClick={addTokenToWallet}>
			Add to <img src={'https://docs.metamask.io/metamask-fox.svg'} alt="Metamask" />
		</div>
	)
}