import {useEffect} from "react";
import {useAppContext} from "./AppProvider";

export const Message = () => {

	const { message, setMessage } = useAppContext();

	useEffect(() => {
		if (message){
			setTimeout(() => {
				setMessage();
			}, 5000);
		}
	}, [message, setMessage]);

	if (!message) {
		return null;
	}

	const {
		type,
		text,
	} = message;

	return (
		<div className={`message-container ${(type==='success'?'success-message':'error-message')}`}>
			<h4 className="mb-0">{text}</h4>
		</div>
	)
}