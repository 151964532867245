import { Link } from "react-router-dom";

function cut(str, cutStart, cutEnd) {
    return str.substr(0, cutStart) + '...' + str.substr(cutEnd + 1);
}

export function Header({ address = "" }) {
    return (
        <div className="top-bar">
            <div className={'top-bar-icon'}>
                <img src="/images/logo.svg" alt="Logo" height={49} width={78} />
                <Link to="/">
                    <h4>Staking</h4>
                </Link>
                <Link to="/game">
                    <h4>Game</h4>
                </Link>
            </div>
            {address ?
                <div className={'top-bar-address'}>
                    <h4 className="mb-0">Connected Wallet: {cut(address, 6, 35)}</h4>
                </div> : null}
        </div>
    )
}