import { Header } from "../Components/Header";
import React, { useCallback, useState } from "react";
import { LoadingSpinner } from "../Components/LoadingSpinner";
import { getStakingRecords, getTokenInfoFromNFTContract } from "../utilities/stakingUtils";
import { StakingProvider, useStakeContext } from "../Components/StakingProvider";
import { TokenListView } from "../Components/TokenListView";
import { Message } from "../Components/Message";
import { AuthButton } from "../Components/AuthButton";
import { getTokenTotalSupply } from "../utilities/tokenUtils";

const STAKING_STATUS = {
	ERROR: 'error',
	SUCCESS: 'success',
	NONE: 'none',
};

const NoTokensView = () => {
	const {
		stakingInfo: {
			tokenData = [],
			address,
		} = {},
	} = useStakeContext();

	if (!address || tokenData.length > 0) {
		return null;
	}
	return (
		<div className="content">
			<span className={'sub-heading'}>No Tokens Found</span>
		</div>
	)
}

export const Staking = () => {

	const [stakingInfo, setStakingInfo] = useState({});
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState({})

	const {
		address,
	} = stakingInfo;

	// console.log(`[Staking][render] stakingInfo: `, stakingInfo);
	// console.log(`[Staking][render] address: ${address}`);

	const updateStakingInfo = useCallback((newStakingInfo) => {
		setStakingInfo(prevState => {
			return { ...prevState, ...newStakingInfo }
		});
	}, [setStakingInfo]);

	const beforeConnect = useCallback(() => {
		setStatus()
		setLoading(true);
	}, []);

	const onError = useCallback((error) => {
		console.error('connect', error)
		setStatus({
			type: STAKING_STATUS.ERROR,
			message: error?.error?.message || error?.reason || error?.message
		});
		setLoading(false);
	}, []);

	const onConnect = useCallback(async (info) => {
		let {
			address,
			provider,
			signer,
		} = info;

		try {
			const {
				totalTokens,
				tokenData
			} = await getTokenInfoFromNFTContract(address);

			// console.log(`[Staking][handleConnect] ${totalTokens}`)
			let stakingRecords = await getStakingRecords(address);

			tokenData.push(...stakingRecords);
			tokenData.sort((a, b) => {
				if (a["tokenId"] > b["tokenId"]) {
					return 1;
				} else if (a["tokenId"] < b["tokenId"]) {
					return -1
				}
				return 0;
			})

			let totalSupply = await getTokenTotalSupply({ signer });
			// console.log(`[Staking][handleConnect] totalSupply:`, totalSupply)
			setStakingInfo({
				address,
				provider,
				signer,
				totalTokens,
				tokenData,
				stakingRecords,
				totalSupply,
			})

		} catch (error) {
			onError(error);
		}
		setLoading(false);
	}, [onError]);

	return (
		<StakingProvider stakingInfo={stakingInfo} updatetStakingInfo={updateStakingInfo}>
			{!address ?
				<div className="welcome-message">
					<h3 className="sub-heading">Welcome to</h3>
					<h1 className="heading">More Than Gamers</h1>
				</div> : null
			}
			<Header address={address} />
			<Message />
			{
				!address && (
					<h1 className={'centered'}>Let&apos;s start staking</h1>
				)
			}
			{
				status?.type && (
					<div className="content">
						<p className={status?.type} dangerouslySetInnerHTML={{ __html: status?.message }} />
					</div>
				)
			}
			{
				!loading ? (
					<AuthButton beforeConnect={beforeConnect}
						onConnect={onConnect}
						onError={onError} />
				) : (<></>)
			}
			{
				loading && (
					<LoadingSpinner />
				)
			}
			<NoTokensView />
			<TokenListView />
		</StakingProvider>
	)
}