import { ethers } from "ethers";
import { ARCADA_TOKEN_CONTRACT } from "../constants";
import ArcadaContractABI from "../abis/arcada_abi.json";

// Originally found at https://docs.metamask.io/guide/registering-your-token.html
export const addTokenToMetaMask = async ({
	ethereum,
	tokenAddress,
	tokenSymbol,
	tokenDecimals,
	tokenImage
}) => {
	if (!ethereum) {
		throw new Error("No crypto wallet found. Please install it.");
	}

	try {
		// wasAdded is a boolean. Like any RPC method, an error may be thrown.
		const wasAdded = await ethereum.request({
			method: 'wallet_watchAsset',
			params: {
				type: 'ERC20', // Initially only supports ERC20, but eventually more!
				options: {
					address: tokenAddress, // The address that the token is at.
					symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
					decimals: tokenDecimals, // The number of decimals in the token
					image: tokenImage, // A string url of the token logo
				},
			},
		});
		console.log(`[addTokenToMetaMask] tokenSymbol: ${tokenSymbol}, wasAdded: ${wasAdded}`);
		return wasAdded;
	} catch (error) {
		console.error(error);
	}
	return false;
}

export const getTokenTotalSupply = async ({
	signer
}) => {
	try {
		const contract = new ethers.Contract(ARCADA_TOKEN_CONTRACT, ArcadaContractABI, signer);
		return await contract.totalSupply();
	} catch (error) {
		console.error(error);
	}
	return 0;
}