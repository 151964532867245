import {addToPendingClaimed, addToPendingStaking, addToPendingUnStaking, useStakeContext} from "./StakingProvider";
import {
	claimRewards,
	hasStakingContractBeenApproved,
	isApprovedForAll,
	stakeTokensToContract,
	unStakeTokensFromContract
} from "../utilities/stakingUtils";
import {useEffect, useState} from "react";
import {LoadingSpinner} from "./LoadingSpinner";

export const StakeButtons = () => {

	const {
		selectedTokens,
		setMessage,
		setSelectedTokens,
		stakingInfo: {
			address,
			tokenData = [],
			signer,
			stakingRecords = [],
		} = {}
	} = useStakeContext();

	const [approvalPending, setApprovalPending] = useState(true);
	const [stakingApproved, setStakingApproved] = useState(false);

	useEffect(() => {
		hasStakingContractBeenApproved({address, signer}).then((approved) => {
			setStakingApproved(approved);
			setApprovalPending(false);
		}).catch((error) => {
			console.log(error);
		})
	}, [setStakingApproved, setApprovalPending, address, signer]);

	if (tokenData.length === 0){
		return null;
	}

	const isApprovedForStaking = async () => {
		return await requestApproveStakingIfNotApproved();
	}

	const stakeTokens =  async () => {
		setMessage();

		// Verify that all tokens are not staked.
		const alreadyStaked = [];
		for (let a = 0; a < selectedTokens.length; a++){
			const stakingRecordIndex = stakingRecords.findIndex(sr => sr["tokenId"] === selectedTokens[a]);
			if (stakingRecordIndex >= 0){
				alreadyStaked.push(selectedTokens[a]);
			}
		}

		if (alreadyStaked.length > 0){
			setSelectedTokens([]);
			setMessage({
				type: 'error',
				text: 'Unable to stake selected tokens because at least 1 token is already staked.'
			})
			return;
		}

		const isApproved = await isApprovedForStaking();
		console.log(`[stakeTokens] isApproved: ${isApproved}`);
		if (!isApproved){
			return
		}

		stakeTokensToContract({
			tokens: selectedTokens,
			signer,
		}).then((response) => {
			const {
				success,
				receipt,
				error
			} = response;

			console.log(receipt)

			if (!success){
				setMessage({
					type: 'error',
					text: error,
				})
			} else {
				selectedTokens.forEach(x => {
					addToPendingStaking(x);
				});
				setSelectedTokens([]);
				setMessage({
					type: 'success',
					text: "Stake token transaction pending."
				})
			}
		}).catch((error) => {
			console.error(error);
			setMessage({
				type: 'error',
				text: error.message,
			})
		});
	};

	const unStakeTokens = async () => {
		setMessage();

		const nowInSeconds = (Date.now()/1000);

		const tokensThatAreNoStaked = [];
		const tokenStakesThatHaveNotExpired = [];
		for (let a = 0; a < selectedTokens.length; a++){
			const stakingRecordIndex = stakingRecords.findIndex(sr => sr["tokenId"] === selectedTokens[a]);
			if (stakingRecordIndex === -1){
				tokensThatAreNoStaked.push(selectedTokens[a]);
			} else {
				if (stakingRecords[stakingRecordIndex]["expire"] > nowInSeconds){
					tokenStakesThatHaveNotExpired.push(selectedTokens);
				}
			}
		}

		if (tokensThatAreNoStaked.length > 0){
			setSelectedTokens([]);
			setMessage({
				type: 'error',
				text: 'Unable to unstake selected tokens because at least 1 token has not been staked.'
			})
			return;
		}

		if (tokenStakesThatHaveNotExpired.length > 0){
			setSelectedTokens([]);
			setMessage({
				type: 'error',
				text: 'Unable to unstake selected tokens because at least 1 token\'s staking has not expired.'
			})
			return;
		}

		unStakeTokensFromContract({
			tokens: selectedTokens,
			address,
			signer
		}).then((response) => {
			const {
				success,
				receipt,
				error
			} = response;

			console.log(receipt)

			if (!success){
				setMessage({
					type: 'error',
					text: error,
				})
			} else {
				selectedTokens.forEach(x => {
					addToPendingUnStaking(x);
				})
				setSelectedTokens([]);
				setMessage({
					type: 'success',
					text: "Un-stake token transaction pending."
				})
			}
		}).catch((error) => {
			console.error(error);
			setMessage({
				type: 'error',
				text: error.message,
			})
		})
	};

	const claimRewardsForAllTokens =  async () => {
		setMessage();

		const isApproved = await isApprovedForStaking();
		console.log(`[claimRewardsForAllTokens] isApproved: ${isApproved}`);
		if (!isApproved){
			return
		}

		const tokensToClaimRewardsFor = stakingRecords.map(sr => sr["tokenId"]);

		claimRewards({
			tokens: tokensToClaimRewardsFor,
			signer,
		}).then((response) => {
			addToPendingClaimed(tokensToClaimRewardsFor);
			const {
				success,
				receipt,
				error
			} = response;

			console.log(receipt)

			if (!success){
				setMessage({
					type: 'error',
					text: error,
				})
			} else {
				setSelectedTokens([]);
				setMessage({
					type: 'success',
					text: "Claim rewards transaction pending."
				})
			}
		}).catch((error) => {
			console.error(error);
			setMessage({
				type: 'error',
				text: error.message,
			})
		});
	};

	const requestApproveStakingIfNotApproved = async () => {
		return await isApprovedForAll({
			address,
			signer,
			onContractInteractionResponse: (response) => {
				console.log(`[StakeButton][stakeTokens] approval response`, response);
				setMessage({
					type: 'success',
					text: "Approval request sent. Wait for response before staking."
				})
			},
			onError: (error) => {
				console.error(error);
				setMessage({
					type: 'error',
					text: "There was a problem granting approval. Please try again"
				})
			},
			onApprovalComplete: (approved) => {
				if (approved) {
					setStakingApproved(approved);
					setMessage({
						type: 'success',
						text: "Contract approval granted. You can now stake."
					})
				} else {
					setMessage({
						type: 'error',
						text: "Contract approval not granted. You can now stake."
					})
				}
				setApprovalPending(false);
			}
		})
	}

	const approveStaking = async () => {
		setApprovalPending(true);
		await requestApproveStakingIfNotApproved();
	}

	console.log(`[StakeButtons] stakingApproved: ${stakingApproved}`);
	return (
		<div className={'stake-buttons-container'}>
			{stakingApproved ?
				<>
					<button className={'buttons-left'} onClick={() => stakeTokens()}>Stake</button>
					<button className={'buttons-middle'} onClick={() => claimRewardsForAllTokens()}>Claim</button>
					<button className={'buttons-right'} onClick={() => unStakeTokens()}>Unstake + Claim</button>
				</> :
				approvalPending
					? <LoadingSpinner/>
					: <button className={'buttons-left'} onClick={() => approveStaking()}>Approve Staking</button>
			}
		</div>

	)
}
