import React, { useCallback, useContext, useState } from "react";
import { ethers } from "ethers";
import { STAKING_CONTRACT } from "../constants";
import abi_staking from "../abis/mtg_staking_abi.json";
import { getStakingRecordForToken } from "../utilities/stakingUtils";
import { useAppContext } from "./AppProvider";

export const StakingContext = React.createContext({});

const handledStakedTokens = [];
const handledUnStakedTokens = [];

const pendingUnStaking = [];
const pendingStaking = [];
const pendingClaim = [];

export const addToPendingUnStaking = (tokenId) => {
	pendingUnStaking.push(tokenId)
}

export const addToPendingStaking = (tokenId) => {
	pendingStaking.push(tokenId);
}

export const addToPendingClaimed = (tokens) => {
	pendingClaim.push(...tokens);
}

export const StakingProvider = (props) => {

	const {
		children,
		stakingInfo,
		updateStakingInfo = () => { },
		stakingInfo: {
			address,
			provider,
			tokenData = [],
		},
		gameInfo,
	} = props;

	const [selectedTokens, setSelectedTokens] = useState([]);
	const { message, setMessage } = useAppContext();

	const addTokenToSelectedList = useCallback((tokenId) => {
		if (selectedTokens.indexOf(tokenId) === -1) {
			selectedTokens.push(tokenId);
			setSelectedTokens(selectedTokens)
		}
	}, [selectedTokens, setSelectedTokens]);

	const removeTokenFromSelectedList = useCallback((tokenId) => {
		const indexOfToken = selectedTokens.indexOf(tokenId);
		if (indexOfToken >= 0) {
			selectedTokens.splice(indexOfToken, 1);
			setSelectedTokens(selectedTokens);
		}
	}, [selectedTokens, setSelectedTokens]);

	const updateStakingInfoForToken = async (tokenId) => {
		const indexOfToken = tokenData.findIndex(t => t["tokenId"] === tokenId);
		if (indexOfToken >= 0) {
			const currentStakedTokenInfo = await getStakingRecordForToken(address, tokenId);
			console.log(`[updateStakingInfoForToken] currentStakedTokenInfo: `, currentStakedTokenInfo);
			if (currentStakedTokenInfo) {
				tokenData[indexOfToken]["expire"] = currentStakedTokenInfo["expire"];
				tokenData[indexOfToken]["reward"] = currentStakedTokenInfo["reward"];
			} else {
				tokenData[indexOfToken]["expire"] = Date.now() + (7 * 24 * 60 * 60);
				tokenData[indexOfToken]["reward"] = 0;
			}
			updateStakingInfo({ tokenData: tokenData });
		}
	}
	const handleStakedEvent = async (owner, id) => {
		if (owner === address) {
			const tokenId = id.toNumber();
			const indexInSelectedTokens = selectedTokens.findIndex(x => x === tokenId);
			const indexInPendingClaimed = pendingClaim.findIndex(x => x === tokenId);
			// console.log(`[handleStakedEvent] tokenId: ${tokenId}, indexInSelectedTokens: ${indexInSelectedTokens}`);
			if (indexInSelectedTokens >= 0 && handledStakedTokens.findIndex(x => x === tokenId) === -1) {
				handledStakedTokens.push(tokenId);
				setSelectedTokens(prevState => {
					return prevState.splice(indexInSelectedTokens, 1);
				});
				setMessage({
					type: 'success',
					text: `Transaction to stake token ${tokenId} completed.`
				});
				await updateStakingInfoForToken(tokenId);
			} else if (indexInPendingClaimed >= 0 && handledStakedTokens.findIndex(x => x === tokenId) === -1){
				handledUnStakedTokens.push(tokenId);
				pendingClaim.splice(indexInPendingClaimed, 1);
				await updateStakingInfoForToken(tokenId);
			}
		}
	}

	const handleUnStakedEvent = (owner, id) => {
		if (owner === address) {
			const tokenId = id.toNumber();
			const indexInSelectedTokens = pendingUnStaking.findIndex(x => x === tokenId);
			if (indexInSelectedTokens >= 0 && handledUnStakedTokens.findIndex(x => x === tokenId) === -1) {
				// const unStakedToken = pendingUnStaking.splice(indexInSelectedTokens, 1);
				handledUnStakedTokens.push(tokenId);
				setSelectedTokens(prevState => {
					return prevState.splice(indexInSelectedTokens, 1);
				});
				// updateContextValuesAfterListenerConfirmations(tokenId);
				setMessage({
					type: 'success',
					text: `Transaction to un-stake token ${tokenId} completed.`
				});
				const indexOfToken = tokenData.findIndex(t => t["tokenId"] === tokenId);
				if (indexOfToken >= 0) {
					delete tokenData[indexOfToken]["expire"];
					updateStakingInfo({
						tokenData: tokenData,
					});
				}
			}
		}
	}

	const handleClaimEvent = (owner, rewards) => {
		if (owner === address){
			if (pendingClaim.length===0){
				console.log(`[handleClaimEvent] owner: ${owner}, rewards: ${rewards}`);
				setMessage({
					type: 'success',
					text: `Transaction to claim rewards completed.`
				});
			}
		}
	}

	if (address) {
		const stakingContract = new ethers.Contract(STAKING_CONTRACT, abi_staking, provider);
		stakingContract.on("Staked", (owner, id) => {
			handleStakedEvent(owner, id).catch((error) => console.error(error));
		});

		stakingContract.on("Unstaked", (owner, tokenId) => {
			handleUnStakedEvent(owner, tokenId);
		});

		stakingContract.on("Claimed", (owner, rewards) => {
			handleClaimEvent(owner, rewards);
		})
	}

	const contextValues = {
		stakingInfo,
		selectedTokens,
		setSelectedTokens,
		addTokenToSelectedList,
		removeTokenFromSelectedList,
		message,
		setMessage,
		gameInfo,
	};

	return (
		<StakingContext.Provider value={contextValues}>
			{children}
		</StakingContext.Provider>
	)
}

export const useStakeContext = () => useContext(StakingContext)