import { TokenView } from "./TokenView";
import { useStakeContext } from "./StakingProvider";
import React, { useCallback, useState } from "react";
import { StakeButtons } from "./StakeButtons";
import { BigNumber, utils } from "ethers";
import { AddToMetaMask } from "./AddToMetaMask";
import { ARCADA_TOKEN_CONTRACT, ETHERSCAN_ADDRESS_ENDPOINT } from "../constants";

export const TokenListView = () => {

	const {
		stakingInfo: {
			tokenData = [],
			address,
			totalSupply = 0,
		} = {},
		selectedTokens = [],
		setSelectedTokens,
	} = useStakeContext();

	const [view, setView] = useState('all');

	const items = [];

	const updateView = useCallback((newView) => {
		if (view !== newView) {
			setView(newView);
		}
	}, [view, setView])

	let noItemsMessage = '';
	let viewedItems;
	if (view === 'un-staked') {
		viewedItems = tokenData.filter(x => x["expire"] === undefined);
		if (viewedItems.length === 0) {
			noItemsMessage = "No un-staked items";
		}
	} else if (view === 'staked') {
		viewedItems = tokenData.filter(x => x["expire"] !== undefined);
		if (items.length === 0) {
			noItemsMessage = "No staked items";
		}
	} else {
		viewedItems = tokenData;
	}

	viewedItems.forEach((token) => {
		items.push(
			<TokenView key={`mtg-${token["tokenId"]}`} token={token} />
		)
	});

	const selectAll = useCallback(() => {
		const newSelectedTokens = [...selectedTokens];
		for (let a = 0; a < viewedItems.length; a++) {
			const tokenId = viewedItems[a]["tokenId"];
			if (newSelectedTokens.indexOf(tokenId) === -1) {
				newSelectedTokens.push(tokenId);
			}
			setSelectedTokens(newSelectedTokens);
		}
	}, [viewedItems, selectedTokens, setSelectedTokens]);

	const displayContract = (tokenAddress) => {
		window.open(`${ETHERSCAN_ADDRESS_ENDPOINT}/${tokenAddress}`, '_blank')
	}
	const unselectAll = useCallback(() => {
		setSelectedTokens([]);
	}, [setSelectedTokens])

	if (!address || tokenData.length === 0)
		return null;

	const totalRewards = tokenData
		.map(a => "reward" in a ? a["reward"] : 0)
		.reduce((previousResult, currentPositionValue) => {
			if (currentPositionValue === 0) {
				return previousResult;
			}
			console.log(`[TokenListView] previousResult: ${previousResult}, currentPositionValue: ${currentPositionValue}`);
			try {
				return BigNumber.from(currentPositionValue).add(BigNumber.from(previousResult));
			} catch (error) {
				console.error(error);
			}
			return 0;
		});

	console.log(`[TokenListView][render] totalRewards: ${totalRewards}`);

	const parsedRewards = parseFloat(utils.formatEther(totalRewards)).toFixed(2);
	const parsedTotalSupply = parseFloat(utils.formatEther(totalSupply)).toFixed(2);

	return (
		<div className={'token-list-container'}>
			<div className="token-stats">
				<div>
					<div className={'total-supply-container'}>
						<h2>TOTAL SUPPLY: {parsedTotalSupply} $ARCADA</h2>
					</div>
					<div className={'total-staked-container'}>
						<h2>MY REWARDS: {parsedRewards} $ARCADA</h2>
					</div>
				</div>
				<div className={'token-actions'}>
					<button className={'buttons-left'} onClick={() => displayContract(`${ARCADA_TOKEN_CONTRACT}`)}>View Contract</button>
					<AddToMetaMask tokenAddress={ARCADA_TOKEN_CONTRACT}
						tokenSymbol={'ARCADA'}
						tokenDecimals={18}
						image={'https://www.morethangamersnft.io/static/image/logo.svg'} />
				</div>
			</div>

			<div className={'token-list-buttons-container'}>
				<div>
					<button className={'buttons-left'} onClick={selectAll}>Select All</button>
					<button className={'buttons-right'} onClick={unselectAll}>Unselect All</button>
				</div>
				<div className={'top-buttons-container-right'}>
					<button className={`buttons-left ${view === 'all' ? `button-selected` : ''}`}
						onClick={() => updateView('all')}>All</button>
					<button className={`buttons-middle ${view === 'un-staked' ? `button-selected` : ''}`}
						onClick={() => updateView('un-staked')}>Unstaked</button>
					<button className={`buttons-right ${view === 'staked' ? `button-selected` : ''}`}
						onClick={() => updateView('staked')}>Staked</button>
				</div>
			</div>

			{items.length > 0
				? <>
					<div className={'tokens-container'}>{items}</div>
					<StakeButtons />
				</>
				: <span className={'sub-heading no-items-container'}>{noItemsMessage}</span>
			}
		</div>

	)
}

