import { ethers } from "ethers";
import { CONTRACT, STAKING_CONTRACT } from "../constants";
import MTGNFTContractABI from "../abis/mtg_abi.json";
import MTGStakingContractABI from "../abis/mtg_staking_abi.json"

export const getTokenInfoFromNFTContract = async (address) => {
	const provider = new ethers.providers.Web3Provider(window.ethereum);
	const contract = new ethers.Contract(CONTRACT, MTGNFTContractABI, provider);

	const totalTokens = (await contract.totalSupply()).toNumber();

	const tokenData = [];
	const tokens = await contract.tokensOfOwner(address, 1, totalTokens + 1);
	for (let a = 0; a < tokens.length; a++) {
		const tokenId = tokens[a].toNumber();
		tokenData.push({ tokenId })
	}
	return {
		totalTokens,
		tokenData,
	}
}

export const getStakingRecordForToken = async (address, tokenId) => {
	const provider = new ethers.providers.Web3Provider(window.ethereum);
	const stakingContract = new ethers.Contract(STAKING_CONTRACT, MTGStakingContractABI, provider);

	const [stakedTokens, expirations, rewards] = await stakingContract.getStakingRecords(address);

	const stakedTokenIndex = stakedTokens.findIndex(x => x.toNumber() === tokenId);
	console.log(`[getStakingRecordForToken] stakedTokenIndex: ${stakedTokenIndex}`);
	if (stakedTokenIndex >= 0) {
		return {
			tokenId,
			metaDataUri: `https://api.opensea.io/api/v1/asset/0x49907029e80dE1cBB3A46fD44247BF8BA8B5f12F/${tokenId}`,
			reward: rewards[stakedTokenIndex],
			expire: expirations[stakedTokenIndex].toNumber(),
		}
	}
	return undefined;
}

export const hasStakingContractBeenApproved = async ({ address, signer }) => {
	const contract = new ethers.Contract(CONTRACT, MTGNFTContractABI, signer);
	const isApproved = await contract.isApprovedForAll(address, STAKING_CONTRACT);
	console.log(`[hasStakingContractBeenApproved] isApproved: ${isApproved}`);
	return isApproved;
}

export const isApprovedForAll = async ({
	address,
	signer,
	onContractInteractionResponse = (response) => { },
	onApprovalComplete = (approved) => { },
	onError = (error) => { }
}) => {
	const contract = new ethers.Contract(CONTRACT, MTGNFTContractABI, signer);
	const isApproved = await contract.isApprovedForAll(address, STAKING_CONTRACT);
	console.log(`[isApprovedForAll] isApproved: ${isApproved}`);
	if (!isApproved) {
		contract.on("ApprovalForAll", async (transactionAddress, operator, approved) => {
			if (transactionAddress === address) {
				onApprovalComplete(approved)
			}
		})
		contract.setApprovalForAll(STAKING_CONTRACT, true).then((response) => {
			onContractInteractionResponse(response);
		}).catch((error) => {
			onError(error);
		})
		return false;
	}
	return true;
}

export const getStakingRecords = async (address) => {
	const currentStakingInfo = [];
	console.log(address);

	const provider = new ethers.providers.Web3Provider(window.ethereum);
	const stakingContract = new ethers.Contract(STAKING_CONTRACT, MTGStakingContractABI, provider);

	const [stakedTokens, expirations, rewards] = await stakingContract.getStakingRecords(address);

	for (let b = 0; b < stakedTokens.length; b++) {
		let tokenId = stakedTokens[b].toNumber();
		let reward = rewards[b];
		let expire = expirations[b].toNumber()
		if (tokenId === 0){
			tokenId = 10100;
			const response = await stakingContract.stakingRecords(tokenId);
			expire = response[2].toNumber();
			reward = await stakingContract.getPendingRewards(tokenId);
		}
		// console.log(`[getStakingRecords][${b}] address: ${address}, tokenId: ${tokenId}, expire: ${expire}, reward: ${reward}`);
		currentStakingInfo.push({
			tokenId,
			reward,
			expire,
		})
	}

	return currentStakingInfo;
}

export const stakeTokensToContract = async ({ tokens = [], signer }) => {
	if (tokens.length === 0) {
		return {
			success: false,
			receipt: {},
			error: "Tokens not selected",
		};
	}

	console.log(`[stakeTokensToContract] tokens:`, tokens);

	try {
		const stakingContract = new ethers.Contract(STAKING_CONTRACT, MTGStakingContractABI, signer);
		const receipt = await stakingContract.batchStake(tokens);
		console.log(`[stakeTokensToContract] receipt`, receipt);
		return {
			success: true,
			receipt,
			error: ''
		}
	} catch (e) {
		console.error(e);
		return {
			success: false,
			receipt: {},
			error: e.message
		}
	}
}

export const claimRewards = async ({ tokens = [], signer }) => {
	if (tokens.length === 0) {
		return {
			success: false,
			receipt: {},
			error: "Tokens not selected",
		};
	}

	console.log(`[reStakeTokensToContract] tokens:`, tokens);

	try {
		const stakingContract = new ethers.Contract(STAKING_CONTRACT, MTGStakingContractABI, signer);
		const receipt = await stakingContract.batchClaim(tokens);
		console.log(`[reStakeTokensToContract] receipt`, receipt);
		return {
			success: true,
			receipt,
			error: ''
		}
	} catch (e) {
		console.error(e);
		return {
			success: false,
			receipt: {},
			error: e.message
		}
	}
}

export const unStakeTokensFromContract = async ({ tokens, signer }) => {
	if (tokens.length === 0) {
		return {
			success: false,
			receipt: {},
			error: "Tokens not selected",
		};
	}
	try {
		const contract = new ethers.Contract(STAKING_CONTRACT, MTGStakingContractABI, signer);
		const receipt = await contract.batchUnstake(tokens);
		console.log(`[unStakedTokens] receipt`, receipt);
		return {
			success: true,
			receipt,
			error: ''
		}
	} catch (e) {
		console.error(e);
		return {
			success: false,
			receipt: {},
			error: e.message
		}
	}
}