import { Waypoint } from "react-waypoint";
import { useCallback, useEffect, useState } from "react";
import { utils } from "ethers";

import { useStakeContext } from "./StakingProvider";

export const TokenView = (props) => {

	const {
		token: passedToken,
	} = props;

	const {
		selectedTokens,
		addTokenToSelectedList,
		removeTokenFromSelectedList,
	} = useStakeContext();

	let isSelectedToStake = selectedTokens.findIndex(t => t === passedToken["tokenId"]) >= 0;
	let expirationDate;

	if (passedToken["expire"] !== undefined) {
		const expirationDateTime = passedToken["expire"];
		// console.log(`[TokenView][${passedToken["tokenId"]}] expirationDateTime: ${expirationDateTime}`);
		expirationDate = new Date((expirationDateTime * 1000))
	}

	let rewards;
	if (passedToken["reward"] !== undefined) {
		const rewardsToDate = passedToken["reward"];
		// console.log(`[TokenView][${passedToken["tokenId"]}] rewardsToDate: ${rewardsToDate}`);
		rewards = rewardsToDate;
	}

	// const [tokenMetaData, setTokenMetaData] = useState({});
	// const [loading, setLoading] = useState(false)

	const [viewData, setViewData] = useState({
		loading: false,
		selected: false,
		tokenMetaData: {}
	});

	const updateViewData = (updatedData) => {
		setViewData(({ loading, tokenMetaData }) => {
			return {
				loading,
				tokenMetaData,
				...updatedData,
			}
		});
	}

	const {
		tokenMetaData,
		loading,
	} = viewData;

	const {
		image,
		name,
	} = tokenMetaData;

	useEffect(() => {
		// console.log(`[TokenView] loading: ${loading}, passedTokenId: ${passedToken['tokenId']}, tokenMetaDataId: ${tokenMetaData["tokenId"]}`)
		if (!loading || (passedToken["tokenId"] === tokenMetaData["tokenId"])) {
			return;
		}

		const { tokenId } = passedToken;
		const metaDataUri = `https://api.opensea.io/api/v1/asset/0x49907029e80dE1cBB3A46fD44247BF8BA8B5f12F/${tokenId}`;

		fetch(metaDataUri)
			.then(response => response.json())
			.then(data => {
				updateViewData({
					loading: false,
					tokenMetaData: {
						// ...data,
						image: data["image_preview_url"],
						name: data["name"],
						tokenId: tokenId,
					}
				})
			}).catch((error) => {
				console.error(error);
				updateViewData({
					loading: false,
					tokenMetaData: {
						tokenId,
						name: `#${tokenId}`,
						image: `https://mtg.mypinata.cloud/ipfs/QmPTjHxha2EGJ1Q4FZBnxVjzCBJqkYbzv3Q3DoXn2SogUe`
					}
				})
			});
	}, [passedToken, tokenMetaData, loading]);

	const toggleSelected = useCallback(() => {

		const {
			tokenId,
		} = passedToken;

		if (isSelectedToStake) {
			removeTokenFromSelectedList(tokenId);
		} else {
			addTokenToSelectedList(tokenId);
		}

		updateViewData({
			selected: true,
		})
	}, [passedToken, isSelectedToStake, addTokenToSelectedList, removeTokenFromSelectedList]);

	const cardClassName = isSelectedToStake ? 'token-card-selected' : 'token-card-un-selected';

	return (
		<div onClick={toggleSelected} className={`token-card ${cardClassName}`}>
			<Waypoint onEnter={() => updateViewData({ loading: true })} />
			<div>
				<div className={'token-info-container'}>
					{(passedToken["tokenId"] === tokenMetaData["tokenId"]) ?
						<>
							<span className={'token-name-label'}>{name}</span>
							<img alt={name} src={image} />
							{
								expirationDate &&
								(
									<>
										<div className={'token-info-label'}>
											<span>Lock:</span>
											<span>{expirationDate.toLocaleDateString()}</span>
										</div>
										<div className={'token-info-label'}>
											<span></span>
											<span>at {expirationDate.toLocaleTimeString()}</span>
										</div>
									</>
								)
							}
							{
								rewards ?
									(
										<div className={'token-info-label'}>
											<span>Rewards:</span>
											<span>{parseFloat(utils.formatEther(rewards)).toFixed(2)}</span>
										</div>
									)
									: null
							}
						</> :
						<span className={'centered'}></span>
					}
				</div>
			</div>
		</div>
	)
}