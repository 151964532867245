import React from "react";
import {connect} from "../utilities/connect";
import {useStakeContext} from "./StakingProvider";
import {useGameContext} from "./GameProvider";

export const AuthButton = (props) => {

	const {
		stakingInfo: {
			address: stakingInfoAddress
		} = {},
	} = (useStakeContext() || {});

	const {
		gameInfo: {
			address: gameInfoAddress
		} = {}
	} = (useGameContext() || {});

	const address = stakingInfoAddress || gameInfoAddress

	if (address){
		return null;
	}

	const {
		beforeConnect = () => {},
		onConnect = (info) => {},
		onError = (error) => {}
	} = props;


	const handleConnect = async () => {
		await beforeConnect();
		try {
			let { address, provider, signer } = await connect();
			await onConnect({address, provider, signer });
		} catch (error) {
			await onError(error);
		}
	}

	return (
		<button onClick={handleConnect}>Connect</button>
	)
}