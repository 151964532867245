import React, {useContext} from "react";

export const GameContext = React.createContext({});

export const GameProvider = (props) => {
	const {
		children,
		gameInfo: {
			address,
			provider,
		},
		gameInfo,
		updateGameInfo = () => {}
	} = props;

	const contextValues = {
		address,
		provider,
		gameInfo,
		updateGameInfo
	}

	return (
		<GameContext.Provider value={contextValues}>
			{children}
		</GameContext.Provider>
	)
}

export const useGameContext = () => useContext(GameContext);