import { AppProvider } from "./AppProvider";

export function Wrapper({ component }) {
    return (
        <AppProvider>
            <div className="container">
                {component}
            </div>
            <footer>


                <a href="//opensea.io/collection/morethangamersnftmtg" target="_blank" rel="noreferrer">
                    <h4>Buy on OpenSea</h4>
                </a>
                <p>© 2022 More Than Gamers. All rights reserved</p>
            </footer>
        </AppProvider>
    )
}