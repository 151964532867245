import { Header } from "../Components/Header";

export const GameOver = () => {
	return (
		<>
			<Header />
			<div className="welcome-message">
				<h3 className="sub-heading">Welcome to</h3>
				<h1 className="heading">More Than Gamers</h1>
			</div>
			<h1 className={'centered'}>Game over</h1>
		</>
	)
}